<template>
    <div>
        <div :class="['day-box', error ? '' : 'mb-3']">
            <div
                v-for="(day, index) in daysCodes"
                :key="index"
                @click="inputDisplay ? dayClick(index) : null"
                :style="inputDisplay ? 'cursor: pointer;' : ''"
                :class="['day-box-item', weekdaysList[index] == '1' ? 'day-box-selected': '']"
                >
                    {{ day }}
            </div>
        </div>
        <div class="red--text mb-3 caption mt-1 ml-1" v-if="error">
            {{ $t("errors.atLeastOneDay") }}
        </div>
    </div>
</template>

<script>
import utilitiesMixin from '@/mixins/utilitiesMixin.js'
    export default {
        name: 'WeekdaysDisplay',
        props: {
            weekdays: {
                type: String,
                required: true
            },
            inputDisplay: {
                type: Boolean,
                default: false
            },
            outputForm: {
                type: String
            },
            rules: {
                type: Boolean,
                default: false
            }
        },
        mixins: [utilitiesMixin],
        data() {
            return {
                daysCodes: this.getDayNames(this.$i18n.locale).map(w => w.slice(0,2)),
                weekdaysList: [],
            }
        },
        computed: {
            error: function () {
                if (!this.inputDisplay || !this.rules) {
                    return false
                }
                else if (this.weekdaysList.join('') == '0000000') {
                    return true
                }
                else {
                    return false
                }
            },
            locale() {
              return this.$i18n.locale;
            }
        },
        methods: {
            dayClick(day) {
                this.weekdaysList.splice(day, 1, this.weekdaysList[day] == '1' ? '0' : '1')
                let output = []
                this.weekdaysList.forEach((element, index) => {
                    if (element == '1') {
                        if (this.outputForm == 'filter') {
                            let x = (index + 1) % 7
                            output.push(x.toString())
                        }
                        else if (this.outputForm == 'form') {
                            output.push(this.getDayNames(this.$i18n.locale)[index])
                        }
                    }
                })
                this.$emit('input', output)
            }
        },
        watch: {
            weekdays: function() {
                this.weekdaysList = this.weekdays.split('')
            },
            locale(loc) {
              this.daysCodes = this.getDayNames(loc).map(w => w.slice(0,2))
            }
        },
        created() {
            this.weekdaysList = this.weekdays.split('')
        }
    }
</script>

<style lang="scss" scoped>
.day-box {
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 250px;
  justify-content: space-around;
  border: 1px solid rgba(194,194,194,0.5);
  border-radius: 10px;
  margin-top: 10px;
}

.day-box-item {
  font-weight: bold;
  color: rgba(194,194,194,0.9);
  font-size: 1.2rem;
}

.day-box-selected {
  color: var(--v-primary-base);
}

@media only screen and (min-width: 1281px) {
    .day-box {
        width: 300px;
    }
}
</style>