<template>
    <div>
        <PopupDialog :dialogOpen="dialog"/>
        <v-row wrap>
            <v-col cols=12 md=4>
                <v-btn text class="primary--text plain" @click="toggleFilters()" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon right class='mr-1'>mdi-filter</v-icon>
                    <span v-text="$t($store.state.showFilters ? 'filter.hide' : 'filter.show')"></span>
                </v-btn>
                <div v-if="$store.state.showFilters || !$vuetify.breakpoint.smAndDown">
                <v-btn text class="primary--text plain my-5" @click="resetFilters()">
                    <v-icon right class='mr-1'>remove</v-icon>
                    <span>{{ $t("filter.clear") }}</span>
                </v-btn>
                <v-card class="py-5 px-5" flat style="border-radius: 20px">
                    <div class="subtitle-1 grey--text text--darken-1 mb-3">{{ $t("filter.region") }}</div>
                    <v-row class="mx-2">
                        <v-col cols=12 class="pa-0">
                            <InputField 
                                class="mt-3"
                                :label="$t('filter.regionLabel')"
                                icon="near_me"
                                v-model="$store.state.filteredRegion"
                                inputType="regionAutocomplete"
                                :regionAll="true"
                                :regionMy="$store.state.region ? true : false"
                                :inputValue="$store.state.filteredRegion"
                            />
                        </v-col>
                    </v-row>
                    <v-divider class="mb-3"></v-divider>
                    <v-row class="ma-0 mb-5">
                        <v-col cols=12> 
                        <InputField
                            :label="$t('filter.type')"
                            icon="local_offer"
                            v-model="$store.state.filteredTags"
                            inputType="selectTags"
                            :inputValue="[...$store.state.filteredTags]"
                            :tagsColumn="true"
                        />
                        </v-col>
                    </v-row>
                    <v-divider class="mb-5"></v-divider>
                    <div class="subtitle-1 grey--text text--darken-1">{{ $t("filter.title") }}</div>
                    <v-row class="mx-2">
                        <v-col cols=12 class="pa-0 mr-2">
                            <InputField
                                class="mt-3"
                                :label="$t('filter.titleLabel')"
                                icon="search"
                                v-model="$store.state.filteredText"
                                inputType="text"
                                :inputValue="$store.state.filteredText"
                            />
                        </v-col>
                    </v-row>
                    <v-divider class="mb-5"></v-divider>
                    <v-row class="ma-0 mb-5">
                        <v-checkbox class="ma-0 mb-3" hide-details v-model="$store.state.showFilteredDate" :label="$t('filter.dateText')"></v-checkbox>
                        <v-col v-if="$store.state.showFilteredDate" cols=12 class="pa-0">
                            <InputField
                                :label="$t('filter.dateLabel')"
                                icon="date_range"
                                v-model="$store.state.filteredDateRange"
                                :inputValue="$store.state.filteredDateRange"
                                inputType="dateRange"
                            />
                            <div class="subtitle-1 grey--text text--darken-1">{{ $t("filter.date") }}</div>
                            <WeekdaysDisplay :inputDisplay="true" v-model="$store.state.filteredDays" :weekdays="parseFilteredDays()" outputForm="filter"/>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 mb-5">
                        <v-checkbox class="ma-0 mb-3" hide-details v-model="$store.state.showLongtime" :label="$t('filter.longTerm')"></v-checkbox>
                    </v-row>
                </v-card>
                </div>
            </v-col>
            <v-col cols=12 md=8>
                <div class="display-1 grey--text text--darken-1 mb-3">{{ $t("filter.all") }}</div>
                <v-row wrap>
                    <v-col cols="12" v-for="(event, index) in displayedEvents.slice((this.$store.state.eventsPage - 1)*this.eventsPerPage, this.$store.state.eventsPage*this.eventsPerPage)" :key="index">
                        <EventCard 
                        :event="event"
                        :showActions="true"
                        @addClick="getlinkedEventsIds"
                        />
                    </v-col>  
                </v-row>
                <v-pagination 
                    @input="paginationClick" 
                    v-if="pageCount" 
                    class="mt-3" 
                    v-model="$store.state.eventsPage" 
                    :length="pageCount"
                    total-visible=9></v-pagination>
                <div v-else>{{ $t("filter.nothingFound") }}</div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import utilitiesMixin from '@/mixins/utilitiesMixin.js'

import EventCard from '@/components/events/EventCard'
import InputField from '@/components/partials/InputField'
import PopupDialog from '@/components/partials/PopupDialog'
import WeekdaysDisplay from '@/components/partials/WeekdaysDisplay'

import axios from 'axios'

    export default {
        name: "EventDisplay",
        components: {EventCard, InputField, PopupDialog, WeekdaysDisplay},
        mixins: [utilitiesMixin],
        data() {
            return {
                eventsPerPage: 5,
                dialog: false,
                publicMode: false,
                events: [],
                linkedEventsIds: [],
                requestedEventsIds: [],
                created: 0
            }
        },
        methods: {
            paginationClick() {
                window.scrollTo(0, 0)
            },
            getEventData() {
                axios.post('/get_offers', {onlyActive: 1})
                .then(response => {
                    this.getEventContent(response.data.offers)
                })
                .catch(() => {
                    this.dialog = true
                })
            },
            getEventContent(ids) {
                let newEvents = []
                axios.post('/get_offers_info', {ID_offers: ids})
                .then(response => {
                    response.data.info.forEach(element => {
                        let newEvent = {
                            ...element,
                            tags: (element.tags || []).map(tag => {
                              if(!this.$store.state.tags[tag]){
                                const tags = this.$i18n.getLocaleMessage('sk-SK').tags;
                                const key = Object.keys(tags).find(t => tags[t].name === tag);  // english tag name

                                return key;
                              } else {
                                return tag;
                              }
                            }),
                            isLinked: this.linkedEventsIds.includes(element.ID_offer),
                            isRequested: this.requestedEventsIds.includes(element.ID_offer),
                            isFull: element.linkedUsers >= element.demand,
                        }
                        newEvents.push(newEvent)
                    });
                })
                .catch(() => {
                    this.dialog = true
                })
                this.events = newEvents
            },
            async getlinkedEventsIds() {
                let newlinkedEventsIds = []
                let newrequestedEventsIds = []
                await axios.get('/auth/get_linked_offers')
                .then(response => {
                    var links = response.data.links
                    for (let i=0;i<links.length;i++) {
                        var link = links[i]
                        if (link.isActive) {
                            newlinkedEventsIds.push(link.ID_offer)
                        }
                    }
                    var requests = response.data.requests
                    for (let i=0;i<requests.length;i++) {
                        newrequestedEventsIds.push(requests[i].ID_offer)
                    }
                })
                .catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        this.dialog = true
                    }
                })
                this.linkedEventsIds = newlinkedEventsIds
                this.requestedEventsIds = newrequestedEventsIds
            },
            toggleFilters() {
                this.$store.state.showFilters = !this.$store.state.showFilters
            },
            containsTag (tags, filteredTags) {
                if (tags) { 
                    for (let tag of tags) {
                        if (filteredTags.includes(tag)) {
                            return true
                        }
                    }
                }
                return false
            },
            resetFilters () {
                this.$store.state.filteredDays = ['1','2','3','4','5','6','0']
                this.$store.state.filteredDateRange = []
                this.$store.state.showFilteredDate = false
                this.$store.state.filteredText = ""
                this.$store.state.filteredRegion = 'data.allRegions'
                this.$store.state.filteredTags = []
            },
            sharedTags(tags1, tags2) {
                let shared = 0
                if (tags1 && tags2 && tags1.length && tags2.length) {
                        [...tags1].forEach(tag => {
                    if (tags2.includes(tag)) {
                        shared += 1
                    }
                    })
                }
                return shared
            }
        },
        computed: {
            pageCount: function() {
                return Math.ceil(this.displayedEvents.length/this.eventsPerPage)
            },
            displayedEvents: function () {
                return this.eventsOrderedByDate.filter(event => {
                    return ((this.$store.state.showLongtime && event.type == 4) ||  
                    (new Date(`${event.endDate || event.nextEventDate}T${event.endTime || "23:59"}:00`) >= new Date &&
                    (event.type == 2 || event.type == 3 || event.date != event.endDate || new Date(`${event.nextEventDate}T${event.nextEventTime}:00`) > new Date) &&
                    (!this.$store.state.showFilteredDate || 
                    (this.isOneOfDaysInRange(this.$store.state.filteredDays, [event.nextEventDate, event.endDate || event.nextEventDate]) &&
                    (!this.$store.state.filteredDateRange.length || 
                    this.doDateRangesOverlap([event.nextEventDate, event.endDate || event.nextEventDate], this.$store.state.filteredDateRange)))))) &&
                    (!this.displayedLinkedRequestedEventIds.includes(event.ID_offer) ||
                    this.$store.state.loggedInFlag == "1") &&
                    (!this.$store.state.filteredText ||
                    event.desc.toLowerCase().includes(this.$store.state.filteredText.toLowerCase()) || 
                    event.place.toLowerCase().includes(this.$store.state.filteredText.toLowerCase()) || 
                    event.title.toLowerCase().includes(this.$store.state.filteredText.toLowerCase())) &&
                    (!this.$store.state.filteredRegion || this.$store.state.filteredRegion == "data.allRegions" ||
                    this.filteredRegionCode == event.region) &&
                    (this.$store.state.filteredTags.length == 0 ||
                    this.containsTag(event.tags, this.$store.state.filteredTags))
                })
            },
            filteredRegionCode: function () {
                let region = this.$store.state.filteredRegion
                let code = 101
                if (region == 'data.myRegion') {
                    code = this.$store.state.region
                } else if (region.length && region != 'data.allRegions') {
                    const r = this.findRegionBy('displayValue', region);
                    code = r && r.region && r.region.code;
                }
                return code
            },
            displayedLinkedRequestedEventIds: function () {
                let newDisplayedLinkedRequestedEventIds = []
                this.events.forEach((event) => {
                    if (this.linkedEventsIds.includes(event.ID_offer) || this.requestedEventsIds.includes(event.ID_offer)) {
                        newDisplayedLinkedRequestedEventIds.push(event.ID_offer)
                    }
                })
                return newDisplayedLinkedRequestedEventIds
            },
            eventsOrderedByDate: function () {
                let orderedEvents = [...this.events]
                orderedEvents.sort((a, b) => {
                    if (a.type == 4) {
                        return 1
                    } else if (b.type == 4) {
                        return -1
                    } else {
                        return new Date(`${a.nextEventDate}T${a.nextEventTime || "00:00"}:00`).getTime() - new Date(`${b.nextEventDate}T${b.nextEventTime || "00:00"}:00`).getTime()
                    }
                })
                const filteredTags = this.$store.state.filteredTags
                if (filteredTags.length > 1) {
                    orderedEvents.sort((a, b) => {
                        return this.sharedTags(b.tags, filteredTags) - this.sharedTags(a.tags, filteredTags)
                    })
                }
                return orderedEvents
            }
        },
        watch: {
            displayedEvents: function () {
                if (this.created > 1) {
                    this.$store.state.eventsPage = 1
                } else {
                    this.created += 1
                }
            }
        },
        async created() {
            if (!this.$store.getters.loggedIn) {
                this.publicMode = true
            }
            if (!this.publicMode) {
                await this.getlinkedEventsIds()
            }
            this.getEventData()
        }
    }
</script>

<style lang="scss" scoped>

</style>